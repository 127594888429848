<template>
  <div class="root-box">
    <el-row class="condition">
      <el-form size="small" :inline="true">
        <el-form-item label="司机姓名：">
          <el-input v-model="param.name" />
        </el-form-item>

        <el-form-item label="司机电话：">
          <el-input v-model="param.telephone" />
        </el-form-item>

        <el-form-item label="司机标签：">
          <el-select clearable="" v-model="param.markLabel" placeholder="请选择" size="small" @change="query()">
            <el-option v-for="item in markLabelOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="审核状态：">
          <el-select clearable @change="query()" v-model="param.auditState" placeholder="请选择" size="small">
            <el-option v-for="item in auditStateOpts" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button style="width: 100px; margin-right: 20px" type="primary" @click="query()">查询</el-button>
        </el-form-item>

        <el-form-item v-if="appCountInfo" style="float: right">
          APP下载量：{{ appCountInfo.total }} （Ios:{{ appCountInfo.ios }} &nbsp;&nbsp; Android:{{
            appCountInfo.android
          }}
          ）
        </el-form-item>
      </el-form>
    </el-row>
    <div class="table">
      <el-table :data="list" stripe max-height="600">
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="telephone" label="电话" width="120"></el-table-column>
        <el-table-column prop="callState" width="70">
          <template slot-scope="scope">
            <driver-call-state :type="scope.row.callState" :driver-id="scope.row.id" />
          </template>
        </el-table-column>
        <el-table-column prop="cardNo" label="身份证号"></el-table-column>
        <el-table-column prop="vehicleNo" label="车牌号"></el-table-column>
        <el-table-column prop="joinCity" label="意向路线"></el-table-column>
        <el-table-column prop="auditState" label="审核状态">
          <template slot-scope="scope">
            <span>
              {{ scope.row.auditState | enumTransfer(driverStateOpts) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="createTime" label="提交时间"></el-table-column>
        <el-table-column v-if="param.auditState === 3" prop="rejectReason" label="驳回原因"></el-table-column>
        <el-table-column v-if="param.auditState === 3" prop="rejectTime" label="驳回时间"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <span v-if="param.auditState === 1">
              <el-button type="text" size="small" @click="openAuditModal(scope.row)">审核</el-button>
            </span>

            <span v-else>
              <el-button type="text" size="small" @click="openAuditModal(scope.row)">查看详情</el-button>
            </span>

            <span v-if="param.auditState === 3">
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" size="small" @click="revoke(scope.row)">撤销驳回</el-button>
            </span>
            <el-dropdown @command="(command) => handleCommand(command, scope.row)" style="margin-left: 10px"
              class="dropdown-meun">
              <span class="el-dropdown-link">
                操作
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="4">注册详情</el-dropdown-item>
                <el-dropdown-item command="2">添加标记</el-dropdown-item>
                <el-dropdown-item command="1">账号解锁</el-dropdown-item>
                <el-dropdown-item command="3">备注</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>

    <el-dialog :visible.sync="labelVisible" title="选择标签" width="500px">
      <el-form :model="form" label-width="120px" size="small" :rules="rule2">
        <el-select v-model="form.markLabel" placeholder="请选择" size="small">
          <el-option v-for="item in markLabelOpts" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
      </el-form>

      <span slot="footer">
        <el-button size="small" @click="labelVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeLabel" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="finishVisible" title="审核结果" width="800px">
      <div>
        <el-form ref="resultForm" :model="form" label-width="120px" size="small" :rules="rule2">
          <el-form-item v-if="form.result === AUDIT_STATES.REJECT" label="重要提示：">
            <div>
              <div>
                1、多项内容不合格，必须逻辑层次清晰罗列出来，请使用1、2、3等数字字样明确注释清楚。
              </div>
              <div>2、驳回内容写完后，请再次自审一遍，防止出现错别字、语意不通等情况</div>
              <div>3、驳回内容严禁出现客服或内容其他员工的个人信息</div>
              <div>4、只能对不合格事项出具驳回意见，不得对无关事项进行额外注释</div>
              <div>5、驳回内容不清楚怎么写，可以咨询主管后，进行书写</div>
              <div>
                6、驳回答复用语不能使用违法（包含且不限于暴力、色情、恐怖、反动、涉政）等词汇
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="form.result === AUDIT_STATES.REJECT" label="驳回原因：" prop="rejectReason">
            <el-input type="textarea" :rows="8" v-model="form.rejectReason" style="width: 580px"></el-input>
          </el-form-item>

          <template v-if="form.result === AUDIT_STATES.PASS">
            <el-form-item label="司机编号：" prop="driverNo">
              <el-input v-model="form.driverNo" placeholder></el-input>
            </el-form-item>

            <el-form-item label="签约城市：" prop="contractingAreaId">
              <el-select v-model="form.contractingAreaId" placeholder="请选择" style="width: 100%">
                <el-option v-for="item in areaOpts" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="保底收入：" prop="baseSalary">
              <el-input v-model="form.baseSalary"></el-input>
            </el-form-item>

            <el-form-item label="保底积分：" prop="basePoints">
              <el-input v-model="form.basePoints"></el-input>
            </el-form-item>

            <el-form-item label="GPS品牌：" prop="gpsBrand">
              <el-input v-model="form.gpsBrand"></el-input>
            </el-form-item>
            <el-form-item label="GPS型号：" prop="gpsModel">
              <el-input v-model="form.gpsModel"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="finishVisible = false">取 消</el-button>
        <el-button type="primary" @click="auditResult(form.result !== AUDIT_STATES.REJECT)" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="contractVisible" title="合同签订信息" width="600px">
      <div>
        <el-form ref="resultForm" :model="form" label-width="160px" size="small" :rules="rule2">
          <el-form-item label="押金" prop="deposit">
            <el-input-number style="width: 200px" v-model="form.deposit" placeholder></el-input-number>
          </el-form-item>

          <el-form-item label="司机提成（千分之）" prop="commission">
            <el-input-number style="width: 200px" v-model="form.commission" placeholder></el-input-number>
          </el-form-item>

          <el-form-item label="合约时长（月份）" prop="contractDuration">
            <el-input-number style="width: 200px" v-model="form.contractDuration" placeholder></el-input-number>
          </el-form-item>

          <el-form-item label="结算方式" prop="settlementMode">
            <el-select v-model="form.settlementMode" style="width: 200px" placeholder="请选择">
              <el-option v-for="item in settlementModeOpts" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="超长假期天数" prop="longLeaveDays">
            <el-input-number style="width: 200px" v-model="form.longLeaveDays" placeholder></el-input-number>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="contractVisible = false">取 消</el-button>
        <el-button type="primary" @click="audit(true)" size="small">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="司机审核" fullscreen :visible.sync="auditVisible" :close-on-press-escape="false"
      :before-close="handleClose">
      <div class="audit-box">
        <div class="step">
          <el-steps :active="step" align-center direction="vertical">
            <el-step title="个人信息"></el-step>
            <el-step title="健康状况信息"></el-step>
            <el-step title="驾照信息"></el-step>
            <el-step title="车辆信息"></el-step>
            <el-step v-if="form.auditState > 1" title="其他信息"></el-step>
          </el-steps>
        </div>
        <div class="content">
          <el-form ref="infoForm1" :model="form" label-width="80px" size="small" inline label-position="top"
            :rules="rule1" :disabled="!editMode">
            <div v-if="step === 1" class="step1">
              <el-divider content-position="left">身份证信息</el-divider>

              <el-row>
                <el-col :span="4">
                  <el-form-item label="人脸：" prop="urlIdCardFront">
                    <el-image :src="form.urlPersonFace" :fit="fit" :preview-src-list="[form.urlPersonFace]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="urlIdCardFront">
                    <slot name="label">
                      <label-tip label="身份证正面：">
                        <div>身份证照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                      </label-tip>
                    </slot>
                    <el-image :src="form.urlIdCardFront" :fit="fit" :preview-src-list="[form.urlIdCardFront]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="urlIdCardBack">
                    <slot name="label">
                      <label-tip label="身份证背面：">
                        <div>
                          <div>
                            1、身份证照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克
                          </div>
                          <div>2、身份证未过有效期</div>
                        </div>
                      </label-tip>
                    </slot>
                    <el-image :src="form.urlIdCardBack" :fit="fit" :preview-src-list="[form.urlIdCardBack]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="urlIdCardPerson">
                    <slot name="label">
                      <label-tip label="手持身份证：">
                        <div>1、本人正脸清晰可见，无口罩、墨镜、帽子遮挡</div>
                        <div>2、本人正脸和身份证必须同框</div>
                        <div>3、身份证需要展示正面（有人像的），且清晰可见</div>
                      </label-tip>
                    </slot>
                    <el-image :src="form.urlIdCardPerson" :fit="fit" :preview-src-list="[form.urlIdCardPerson]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="人脸检测结果：">
                    <span :class="{ red: form.faceValidateResult !== 1 }">
                      {{ form.faceValidateResultStr }}</span>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="手机号码：">
                    <el-input v-model="form.telephone" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="姓名：">
                        <div>必须和身份证姓名一模一样</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.name" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="身份证号：">
                    <el-input v-model="form.cardNo" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="民族：">
                    <el-input v-model="form.nation" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="户口登记机关：">
                    <el-input v-model="form.census" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="户口住址：">
                    <el-input v-model="form.address" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="出生日期：">
                    <el-input v-model="form.birthday" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-divider content-position="left">其他信息</el-divider>
              <el-row>

                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="紧急联系人电话1：">
                        <div>
                          待无犯罪记录证明开具后，客服与紧急联系人电话联系核实，如果信息不对，无犯罪记录证明哪一环审核不予通过，电话无法接通或关机的可以多打几次，2天连续无法接通，驳回
                        </div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.emergencyContactPhone" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="紧急联系人2：">
                    <el-input v-model="form.emergencyContactPhone2" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="婚姻状况：">
                    <el-input v-model="form.maritalStatus" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="家庭住址：">
                        <div>与紧急联系人核实（只需大概住址即可，能提供小区或接到名称即可）</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.contactAddress" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="学历：">
                    <el-select v-model="form.education" placeholder="请选择">
                      <el-option v-for="item in educationOpts" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="外语能力：">
                    <el-select v-model="form.languageLevel" placeholder="请选择">
                      <el-option v-for="item in languageLevelOpts" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div v-if="step === 2" class="step1">
              <el-divider content-position="left">个人健康状况</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="有无体检记录">
                    <el-input v-model="form.hasPhysicalExaminationRecord" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="体检结果：">
                        <div>1、健康状况采取承诺制，不考核真实性</div>
                        <div>
                          2、如果有上报重大疾病或传染性疾病，请驳回，驳回理由：健康状况不合标准
                        </div>
                        <div>3、如果有上报重大疾病治疗记录，请找主管复核，以主管意见为准</div>
                        <div>4、如果有慢性病记录上报，请找主管复核，以主管意见为准</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.physicalExaminationResult" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="有无慢性疾病">
                    <el-input v-model="form.hasChronicDisease" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="慢性疾病详情">
                    <el-input type="textarea" :rows="4" v-model="form.chronicDisease" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="有无重大疾病">
                    <el-input v-model="form.hasMajorDisease" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="有无传染性疾病">
                    <el-input v-model="form.hasInfectiousDisease" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="曾经有无较大病/伤治疗记录">
                    <el-input v-model="form.hasTreatmentRecord" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="较大病/伤治疗记录">
                    <el-input type="textarea" :rows="4" v-model="form.treatmentRecord" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>

            <div v-if="step === 3" class="step1">
              <el-divider content-position="left">驾照信息</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="驾照正本：">
                        <div>照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlLicense" :preview-src-list="[form.urlLicense]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="驾照副本：">
                        <div>1、驾照照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                        <div>2、驾照未过有效期</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlLicenseCopy" :preview-src-list="[form.urlLicenseCopy]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item label="驾驶证号：">
                    <el-input v-model="form.licenseId" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="准驾车型：">
                    <el-input v-model="form.driverType" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="初次领证日期：">
                    <el-input v-model="form.getDriverLicenseDate" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="驾驶证有效期起：">
                    <el-input v-model="form.driverLicenseOn" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="驾驶证有效期止：">
                    <el-input v-model="form.driverLicenseOff" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
              </el-row>
            </div>

            <div v-if="step === 4" class="step1">
              <el-divider content-position="left">车辆基本信息</el-divider>

              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="行驶证正本：">
                        <div>照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlDriverLicense" :preview-src-list="[form.urlDriverLicense]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="行驶证副本：">
                        <div>1、照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                        <div>2、行车证在有效期内</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlDriverLicenseCopy"
                      :preview-src-list="[form.urlDriverLicenseCopy]" :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车辆左前方照片：">
                        <div>
                          1、照片亮度够，如果是夜晚拍摄，请确认车身颜色、轮廓、车牌号清晰可见，否则驳回
                        </div>
                        <div>2、车辆本身无遮挡，车牌号清晰可见，拍摄角度正确</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlCarLeft" :preview-src-list="[form.urlCarLeft]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="车辆正后方照片：">
                    <el-image :fit="fit" :src="form.urlCarBack" :preview-src-list="[form.urlCarBack]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车辆内部正副驾驶照">
                        <div>
                          1、照片亮度够，如果是夜晚拍摄，请确认车内座位颜色，轮廓清晰可见，否则驳回
                        </div>
                        <div>
                          2、必须可见主副驾驶两个座位，座位上或车内不能有衣物、行李等遮挡，否则驳回
                        </div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlCarInner1" :preview-src-list="[form.urlCarInner1]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车辆内部后排拍照">
                        <div>
                          1、照片亮度够，如果是夜晚拍摄，请确认车内座位颜色，轮廓清晰可见，否则驳回
                        </div>
                        <div>
                          2、必须清晰可见后排座位，座位上或车内不能有衣物、行李等遮挡，否则驳回
                        </div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlCarInner2" :preview-src-list="[form.urlCarInner2]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="商业保险单：">
                        <div>1、驾照照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克</div>
                        <div>2、保单在有效期内</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlInsurance" :preview-src-list="[form.urlInsurance]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车牌号：">
                        <div>必须与行车证、车辆照片上的号码一一致（3个号码一致），否则驳回</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.vehicleNo" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车辆所有人：">
                        <div>1、可以与司机身份证姓名、行车证姓名一致</div>
                        <div>2、可以与行车证车主姓名一致</div>
                        <div>3、如果车主非本人，需要查验后续的车主资料信息</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.ownerName" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="发动机号：">
                    <el-input v-model="form.engineId" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆型号：">
                    <el-input v-model="form.model" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="brand">
                    <slot name="label">
                      <label-tip label="车辆厂牌：">
                        <div>1、品牌与行车证名称吻合</div>
                        <div>
                          2、正确格式为 品牌+型号
                          （此处不能带颜色，带颜色或其他信息，请审核客服删除）
                        </div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.brand"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="vehicleColor">
                    <slot name="label">
                      <label-tip label="车身颜色：">
                        <div>1、 需要与车辆照片颜色或行车证颜色一致</div>
                        <div>2、小众或冷门颜色，选接近颜色即可</div>
                      </label-tip>
                    </slot>
                    <el-select v-model="form.vehicleColor" filterable allow-create placeholder="请选择">
                      <el-option v-for="item in vehicleColorOpts" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="车辆类型" prop="vehicleType">
                    <el-select v-model="form.vehicleType" placeholder="请选择">
                      <el-option v-for="item in vehicleTypeOpts" :key="item.id" :label="item.content"
                        :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item prop="totalMile">
                    <slot name="label">
                      <label-tip label="行驶里程（公里）：">
                        <div>1、 行驶里程大于30万公里，驳回申请，理由：车辆里程过长</div>
                        <div>2、 行驶里程大于15万公里，请示主管审核，以主管审核意见为准</div>
                      </label-tip>
                    </slot>
                    <el-input disabled v-model="form.totalMile" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item prop="carPriceStr">
                    <slot name="label">
                      <label-tip label="裸车价格（万元）：">
                        <div>1、价格低于7万元，驳回申请，理由：车辆价格较低，无法受理申请</div>
                        <div>
                          2、裸车价格是指新车价格，如果是二手车，价格需要参同款新车最低价格为准，如有疑问请请示主管意见
                        </div>
                      </label-tip>
                    </slot>
                    <el-input disabled v-model="form.carPriceStr" style="width: 200px"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="车坐数（包括司机）：">
                    <el-input v-model="form.seats" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="4">
                  <el-form-item label="车辆VIN码：">
                    <el-input v-model="form.vin" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="车辆注册日期：">
                        <div>注册日期距今超过8年（整），驳回申请，理由车龄较长</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.certifyDateA" :disabled="!editMode"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="车辆燃料类型：" prop="fuelType">
                    <el-select v-model="form.fuelType" placeholder="请选择">
                      <el-option v-for="item in fuelTypeOpts" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="engineDisplace">
                    <slot name="label">
                      <label-tip label="发动机排量：">
                        <div>1、需要手动选择与司机标注排量接近的值</div>
                        <div>2、排量带T或L不影响排量值，请按数值对应选择</div>
                      </label-tip>
                    </slot>
                    <el-select v-model="form.engineDisplace" placeholder="请选择">
                      <el-option v-for="item in engineDisplaceOpts" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item prop="nextFixDate">
                    <slot name="label">
                      <label-tip label="下次年检日期：">
                        <div>
                          必须大于司机提交审核的日期（一般为当天），否则驳回。理由：下次年检日期不正确
                        </div>
                      </label-tip>
                    </slot>
                    <el-date-picker placeholder="选择时间" value-format="yyyy-MM-dd"
                      v-model="form.nextFixDate"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>

              <div v-if="form.ownerName !== form.name">
                <el-divider content-position="left">非本人车辆关系证明</el-divider>

                <el-row>
                  <el-col :span="4">
                    <el-form-item>
                      <slot name="label">
                        <label-tip label="与车辆所有人关系：">
                          <div>
                            1、车主的身份证照片（照片亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克）
                          </div>
                          <div>2、车主姓名信息必须与行车证上车主姓名完全一致</div>
                        </label-tip>
                      </slot>
                      <el-input v-model="form.ownerRelation" disabled></el-input>
                    </el-form-item>

                    <el-form-item>
                      <slot name="label">
                        <label-tip label="车主电话：">
                          <div>客服需要核对车主电话，与车主电话联系，以下事项全部合格方为合格</div>
                          <div>1、 表明客服身份</div>
                          <div>
                            2、
                            其次核对车主身份（让车主报上姓名，身份证号码和车辆型号与号牌信息，如有误不用下一步了，直接驳回注册）
                          </div>
                          <div>3、 再次询问车主是否知道被人用车注册城小班司机的事宜</div>
                          <div>4、 最后确认车主是否同意。</div>
                        </label-tip>
                      </slot>
                      <el-input v-model="form.ownerTel" disabled></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="4">
                    <el-form-item label="车辆所有人关系证明1：">
                      <el-image :fit="fit" :src="form.ownerRelationCertify1"
                        :preview-src-list="[form.ownerRelationCertify1]" :z-index="3000"></el-image>
                    </el-form-item>
                  </el-col>

                  <el-col :span="4">
                    <el-form-item label="车辆所有人关系证明2：">
                      <el-image :fit="fit" :src="form.ownerRelationCertify2"
                        :preview-src-list="[form.ownerRelationCertify2]" :z-index="3000"></el-image>
                    </el-form-item>
                  </el-col>

                  <el-col :span="4">
                    <el-form-item label="车辆所有人关系证明3：">
                      <el-image :fit="fit" :src="form.ownerRelationCertify3"
                        :preview-src-list="[form.ownerRelationCertify3]" :z-index="3000"></el-image>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>

            <div v-if="step === 5" class="step1">
              <el-divider content-position="left">银行卡信息</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="银行卡照片：">
                        <div>
                          照片必须亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克，无模糊
                        </div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlBankCard" :preview-src-list="[form.urlBankCard]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="开户行：">
                        <div>必须与银行卡照片上的信息一致</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.bankInfo" disabled></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="银行卡号：">
                        <div>必须与银行卡照片上的信息一致</div>
                      </label-tip>
                    </slot>
                    <el-input v-model="form.bankCardNo" disabled></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4">
                  <el-form-item label="支付宝账号：">
                    <el-input v-model="form.aliPayAccount" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-divider content-position="left">无犯罪记录证明</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="无犯罪记录证明：">
                        <div>1、必须加盖身份证户籍所在地的公安机关（派出所）公章</div>
                        <div>2、姓名与身份证号必须与司机身份证信息完全一致</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlNoCrime" :preview-src-list="[form.urlNoCrime]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-divider content-position="left">一寸登记照</el-divider>
              <el-row>
                <el-col :span="4">
                  <el-form-item>
                    <slot name="label">
                      <label-tip label="一寸登记照：">
                        <div>
                          1、照片必须亮度够，内容清晰可见、无缺漏、无遮挡、无反光、无马赛克，无模糊
                        </div>
                        <div>2、不能戴口罩、墨镜、帽子等遮挡性物品</div>
                        <div>3、必须蓝底</div>
                        <div>4、不能是翻拍纸质版相片或翻拍手机照片，必须是电子档登记照</div>
                        <div>5、不能是手机自拍</div>
                        <div>6、不能赤膊或异常着装拍照，必须是正装或正常着装</div>
                        <div>7、必须是司机本人（与身份证照片核验一致）</div>
                      </label-tip>
                    </slot>
                    <el-image :fit="fit" :src="form.urlPhoto" :preview-src-list="[form.urlPhoto]"
                      :z-index="3000"></el-image>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>

          <span class="btns">
            <el-button v-if="editMode" type="warning" size="small" plain @click="audit(false)">不通过</el-button>
            <el-button v-if="step !== 1" type="primary" size="small" plain @click="pre">{{
              editMode ? "上一步" : "上一页"
            }}</el-button>
            <el-button v-if="showAuditBtn && form.auditState === 5" type="success" size="small"
              @click="contractVisible = true">完成审核</el-button>
            <el-button v-else-if="showAuditBtn" type="success" size="small" @click="audit(true)">{{
              editMode ? "完成审核" : "关闭"
            }}</el-button>
            <el-button v-else type="primary" size="small" plain @click="next">{{
              step === 5 ? "关闭" : "下一页"
            }}</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { IMG_TYPE } from "../consts";
import {
  getDriverRegisterList,
  auditDriver,
  revokeReject,
  unlockDriver,
  countDriverApp,
  updateUnImportInfo,
  updatePrepareDriverInfo,
} from "@/api";
import { BaseDataService } from "@/service";
import { mapState } from "vuex";
import { ParamUtil } from "@/util";
import _ from "lodash";
import DriverCallState from "@/components/CallStatus/driver.vue";

const AUDIT_STATES = {
  PASS: 1,
  REJECT: 0,
};

export default {
  name: "DriverReview",

  components: {
    DriverCallState,
  },

  created() {
    this.query();
  },
  computed: {
    ...mapState({
      size: (state) => state.btnSize,
      driverStateOpts: (state) => state.enumMap["DRIVER_AUDIT_STATE"],
      settlementModeOpts: (state) => state.enumMap["SETTLEMENT_MODE"],
      teamOpts: (state) => state.teamOpts,
      vehicleTypeOpts: (state) => state.vehicleTypeOpts,
      areaOpts: (state) => state.areaOpts,
    }),
    active: {
      get: function () {
        return this.param.auditState + "";
      },
      set: function (val) {
        this.param.auditState = parseInt(val);
      },
    },

    showAuditBtn() {
      const audit1 =
        (this.form.auditState === 1 && this.form.withCar === 1 && this.step === 4) ||
        (this.form.auditState === 1 && !this.form.withCar && this.step === 3);
      const audit2 = this.form.auditState === 5 && this.step === 5;
      if (audit1 || audit2) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      IMG_TYPE,
      AUDIT_STATES,
      param: {
        markLabel: 1,
        auditState: 1,
      },
      fit: "contain",
      form: {},
      seatsOpts: BaseDataService.seatsOpts(),
      vehicleColorOpts: BaseDataService.vehicleColorOpts(),
      markLabelOpts: [
        {
          value: 1,
          label: "普通司机",
        },
        {
          value: 2,
          label: "备用司机",
        },
        {
          value: 3,
          label: "不适合司机",
        },
        {
          value: 4,
          label: "其他",
        },
      ],
      auditStateOpts: [
        {
          value: 0,
          label: "提交资料",
        },
        {
          value: 1,
          label: "待审",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      list: [],
      total: 0,
      currentPage: 1,
      auditVisible: false,
      finishVisible: false,
      contractVisible: false,
      dialogImageUrl: "",
      carOpts: [],
      vehicleId: null,
      step: 1,
      engineDisplaceOpts: BaseDataService.engineDisplaceOpts(),
      languageLevelOpts: ["精通", "熟练", "良好", "一般"],
      fuelTypeOpts: BaseDataService.fuelTypeOpts(),
      educationOpts: [
        {
          value: "19",
          label: "研究生",
        },
        {
          value: "21",
          label: "大学本科",
        },
        {
          value: "31",
          label: "大学专科",
        },
        {
          value: "41",
          label: "中等专科毕业",
        },
        {
          value: "61",
          label: "普通高中毕业",
        },
        {
          value: "71",
          label: "初中毕业",
        },
        {
          value: "81",
          label: "小学毕业",
        },
      ],
      auditOpts: [
        { label: "通过", value: AUDIT_STATES.PASS },
        { label: "不通过", value: AUDIT_STATES.REJECT },
      ],
      rule1: {
        brand: [
          {
            required: true,
            message: "请录入车辆厂牌",
            trigger: "blur",
          },
        ],
        vehicleColor: [
          {
            required: true,
            message: "请选择车身颜色",
            trigger: "blur",
          },
        ],
        seats: [
          {
            required: true,
            message: "请选择核定载客位数",
            trigger: "blur",
          },
        ],
        gpsImei: [
          {
            required: true,
            message: "请输入卫星定位装置IMEI号",
            trigger: "blur",
          },
          {
            pattern: /^(\w){10,20}$/,
            message: "IMEI格式不正确",
            trigger: "blur",
          },
        ],
        contractOn: [
          {
            required: true,
            message: "请输入合同有效期起",
            trigger: "blur",
          },
        ],
        contractOff: [
          {
            required: true,
            message: "请输入合同有效期止",
            trigger: "blur",
          },
        ],
        commission: [
          {
            required: true,
            message: "请输入提成比例",
            trigger: "blur",
          },
        ],
        minCommission: [
          {
            required: true,
            message: "请输入最低提成比例",
            trigger: "blur",
          },
        ],
        maxCommission: [
          {
            required: true,
            message: "请输入最高提成比例",
            trigger: "blur",
          },
        ],
        attendance: [
          {
            required: true,
            message: "请输入上座率",
            trigger: "blur",
          },
        ],
        deposit: [
          {
            required: true,
            message: "请输入押金金额",
            trigger: "blur",
          },
        ],
        vehicleType: [
          {
            required: true,
            message: "请选择车辆类型",
            trigger: "blur",
          },
        ],
      },
      rule2: {
        result: [
          {
            required: true,
            message: "审核结果必填",
            trigger: "blur",
          },
        ],
        team: [{ required: true, message: "车队必填", trigger: "blur" }],
        driverNo: [
          {
            required: true,
            message: "司机编号必填",
            trigger: "blur",
          },
        ],
        vehicleType: [
          {
            required: true,
            message: "车辆类型必填",
            trigger: "blur",
          },
        ],
      },
      editMode: false,
      appCountInfo: null,
      labelVisible: false,
    };
  },
  methods: {
    auditCancel() {
      this.auditVisible = false;
      this.contractVisible = false;
    },

    addRemark(item) {
      this.$prompt(`请输入备注信息`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: `请输入备注信息`,
        closeOnClickModal: false,
      }).then(
        ({ value }) => {
          item.remark = value;
          updatePrepareDriverInfo(item).then(() => {
            this.$message.success("操作成功");
            this.query();
          });
        },
        () => { }
      );
    },

    next() {
      const currentStep = this.step;
      const vaildArr = { 3: ["vehicleColor", "brand"] };
      if (this.editMode && vaildArr[currentStep]) {
        this.$refs.infoForm1.validate((valid, error) => {
          let isOk = true;
          for (let item in error) {
            if (vaildArr[currentStep].indexOf(item) > -1) {
              isOk = false;
              break;
            }
          }
          if (isOk) {
            this.step += 1;
          }
        });
      } else {
        if (this.step < 5) {
          this.step += 1;
        } else {
          this.auditVisible = false;
        }
      }
    },

    pre() {
      this.step -= 1;
    },

    query(page = 1, pageSize = 10) {
      const _param = { ...this.param };

      // 待审状态
      if (_param.auditState === 1) {
        _param.auditState = [1, 5, 7];
      } else if (_param.auditState === 0) {
        // 提交资料
        _param.auditState = [0, 4, 6];
      } else if (_param.auditState) {
        _param.auditState = [_param.auditState];
      }

      const params = {
        page: {
          current: page,
          size: pageSize,
        },
        ...ParamUtil.filterEmptyData(_param),
      };
      getDriverRegisterList(params).then((res) => {
        const { records = [], current = 1, total = 0 } = res.data || {};
        this.list = records;
        this.currentPage = current;
        this.total = total;
      });

      countDriverApp().then((res) => {
        this.appCountInfo = res.data;
      });
    },

    handleCommand(type, item) {
      if (type === "1") {
        this.unlockAccount(item);
      } else if (type === "2") {
        this.form = item;
        this.labelVisible = true;
      } else if (type === "3") {
        this.addRemark(item);
      } else if (type === "4") {
        this.openAuditModal(item);
      }
    },

    unlockAccount(item) {
      unlockDriver(item.id).then(() => {
        this.$message.success("账户已解锁");
      });
    },

    changeLabel() {
      updateUnImportInfo(this.form).then(() => {
        this.labelVisible = false;
        this.$message.success("更新成功");
        this.query();
      });
    },

    audit(isPass) {
      if (this.editMode) {
        if (isPass) {
          this.$refs.infoForm1.validate((valid) => {
            if (!valid) {
              return;
            }
            this.$confirm("确认审核通过吗？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              const param = { ...this.form };
              auditDriver(param, isPass).then(() => {
                this.$message.success("操作成功");
                this.query();
                this.auditCancel();
              });
            });
          });
        } else {
          this.finishVisible = true;
          this.$set(this.form, "result", AUDIT_STATES.REJECT);
        }
      } else {
        this.auditVisible = true;
      }
    },

    revoke(item) {
      this.$confirm("确认撤销吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        revokeReject(item).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },

    auditResult(isPass) {
      const param = { ...this.form };
      auditDriver(param, isPass).then(() => {
        this.finishVisible = false;
        this.$message.success("操作成功");
        this.query();
        this.auditCancel();
      });
    },

    openAuditModal(item) {
      const defVal = {
        gpsBrand: "途强",
        gpsModel: "GV13",
        fuelType: "A",
        baseSalary: 5000,
        basePoints: 5000,
        settlementMode: 1,
      };
      this.form = _.cloneDeep({ ...defVal, ...item });
      const auditState = item.auditState;
      if (auditState === 1) {
        this.step = 1;
        this.auditVisible = true;
        this.editMode = true;
      } else if (auditState === 5) {
        this.step = 5;
        this.auditVisible = true;
        this.editMode = true;
      } else if (auditState === 7) {
        this.finishVisible = true;
        this.$set(this.form, "result", AUDIT_STATES.PASS);
        this.editMode = true;
      } else {
        this.step = 1;
        this.auditVisible = true;
        this.editMode = false;
      }
    },

    handleCurrentChange(page) {
      this.query(page);
    },

    reject() {
      this.finishVisible = true;
      this.$set(this.form, "result", AUDIT_STATES.REJECT);
    },

    handleClose() {
      this.auditVisible = false;
      this.step = 1;
    },

    del(item) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const url = "/admin/driver/del";
        const data = {
          id: item.id,
        };
        this.$http.post(url, data).then(() => {
          this.$message.success("操作成功");
          this.query();
        });
      });
    },
  },
};
</script>

<style scoped lang="less">
.head-box {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: space-between;

  .btn-add {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 80px;
  }
}

.audit-box {
  display: flex;
  height: 800px;
  position: relative;

  .step {
    width: 200px;
    height: 100%;
  }

  .content {
    flex: 1;
  }

  .btns {
    position: absolute;
    width: 500px;
    right: 100px;
    bottom: 60px;
    display: flex;
    justify-content: flex-end;

    button {
      width: 90px;
    }
  }

  /deep/ .el-image {
    width: 200px;
    height: 120px;
  }

  .step1 {
    /deep/ .el-row {
      margin-left: 40px;
    }

    /deep/ .el-divider {
      margin-bottom: 40px;
    }
  }
}
</style>
